<template>
  <v-container class="window" style="margin-left:11%; margin-right:11%; width: 78%;">
    <v-data-table :headers="headers" :items="userData" sort-by="calories" class="elevation-1" :search="search">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Customers</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="600px" scrollable>
            <!-- <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="success"
              dark
              class="mb-2"
              v-bind="attrs"
              v-on="on"
              @click="deleteValidator"
            >
              Tambah Customers
            </v-btn>
          </template> -->
            <v-card>
              <v-toolbar color="primary" dark>{{ formTitle }}</v-toolbar>
              <v-card-text>
                <v-container>
                  <v-row>
                    <!-- <v-col cols="12">
                      <v-text-field v-model="editedItem.customer_number" label="Customer Number" type="number">
                      </v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field v-model="editedItem.customer_name" label="Customer Name"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field v-model="editedItem.description" label="Description"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field v-model="editedItem.email" label="Email"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field v-model="editedItem.npwp" label="NPWP" counter maxlength="21"></v-text-field>
                    </v-col> -->
                    <v-col cols="12">
                      <p>FLag Bupot</p>
                      <v-radio-group v-model="editedItem.flag_bupot" row>
                        <v-radio label="Y" value="Y"></v-radio>
                        <v-radio label="N" value="N"></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">
                  Cancel
                </v-btn>
                <v-btn color="blue darken-1" text @click="save">
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
        <v-divider></v-divider>
        <v-text-field v-model="search" label="Cari Supplier" class="mx-4" prepend-icon="mdi-magnify">
        </v-text-field>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)">
          mdi-pencil
        </v-icon>
        <!-- <v-icon small @click="deleteItem(item)">
          mdi-delete
        </v-icon> -->
      </template>
      <template v-slot:[`item.flag_bupot`]="{ item }">
        <v-chip :color="getColor(item.flag_bupot)" dark>
          {{ item.flag_bupot }}
        </v-chip>
      </template>
      <!-- <template v-slot:no-data>
      <v-btn
        color="primary"
        @click="initialize"
      >
        Reset
      </v-btn>
    </template> -->
    </v-data-table>
  </v-container>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex'
export default {
  data: () => ({
    dialog: false,
    dialogDelete: false,
    headers: [
      // { text: 'Cabang', value: 'CONCAT_BRANCH' },
      { text: 'Customer Number', value: 'customer_number' },
      { text: 'Customer Name', value: 'customer_name' },
      { text: 'Description', value: 'description' },
      { text: 'Npwp', value: 'npwp' },
      { text: 'Flag Bupot', value: 'flag_bupot', align: 'left', sortable: false },
      { text: 'Actions', value: 'actions', sortable: false, align: 'center' }
    ],
    editedIndex: -1,
    editedItem: {},
    userData: [],
    userUpdate: {},
    defaultItem: {},
    resetValidation: [],
    validation: [],
    search: ''
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'Tambah Customer' : 'Edit Customer'
    },
    ...mapGetters({
      user: 'user',
    })
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },

  created() {
    let uri = `v1/customers`;
    axios.get(uri).then(response => {
      this.userData = response.data.data;
    });
  },

  methods: {
    editItem(item) {
      this.deleteValidator();
      this.editedIndex = this.userData.indexOf(item)
      this.editedItem = Object.assign({}, item)
      // console.log(this.editedItem);
      this.dialog = true
    },
    getColor(item) {
      if (item === 'Y') {
        return 'green';
      } else {
        return 'red';
      }
    },
    deleteItem(item) {
      this.editedIndex = this.userData.indexOf(item)
      this.editedItem = Object.assign({}, item)
      // this.dialogDelete = true

      this.$swal.fire({
        title: 'Apakah anda ingin menghapus data ini?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Hapus'
      }).then((result) => {
        if (result.isConfirmed) {
          axios({
            method: 'delete',
            url: 'v1/deletecustomers',
            data: {
              id: this.editedItem.id
            },
          })
            .then(() => {
              let uri = `v1/customers`;
              axios.get(uri).then(response => {
                this.userData = response.data.data;
              });
              this.$swal.fire(
                'Sukses!',
                'Data Berhasil dihapus',
                'success'
              )
              this.closeDelete()
            })
            .catch(error => {
              console.log(error.response)
              this.$swal.fire(
                'Gagal!',
                'Data Gagal dihapus',
                'warning'
              )
            })
        }
      })
    },
    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    save() {
      if (this.editedIndex > -1) {
        this.$swal.fire({
          title: 'Apakah anda ingin mengupdate data ini?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Update'
        }).then((result) => {
          if (result.isConfirmed) {
            axios({
              method: 'post',
              url: 'v1/updatecustomers',
              data: this.editedItem
            })
              .then(() => {
                let uri = `v1/customers`;
                axios.get(uri).then(response => {
                  this.userData = response.data.data;
                });
                this.$swal.fire(
                  'Sukses!',
                  'Data berhasil di update!',
                  'success'
                )
                this.close();
              })
              .catch(error => {
                this.validation = error.response.data.data;
                this.$swal.fire(
                  'Gagal!',
                  'Data Gagal diupdate',
                  'warning'
                )
              })
          }
        })
      } else {
        this.$swal.fire({
          title: 'Apakah anda ingin menambahkan data ini?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Tambah'
        }).then((result) => {
          if (result.isConfirmed) {
            axios({
              method: 'post',
              url: 'v1/createcustomers',
              data: {
                dataCustomer: this.editedItem,
                id: this.user.id
              }
            })
              .then(() => {
                let uri = `v1/customers`;
                axios.get(uri).then(response => {
                  this.userData = response.data.data;
                });
                this.$swal.fire(
                  'Sukses!',
                  'Data berhasil di simpan!',
                  'success'
                )
                this.close();
              })
              .catch(error => {
                this.validation = error.response.data.data;
                this.$swal.fire(
                  'Gagal!',
                  'Data Gagal disimpan',
                  'warning'
                )
              })
          }
        })
      }
    },
    deleteValidator() {
      this.validation = [];
      this.validation.splice(0);
    }
  },

}
</script>